import React from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import Wysiwyg from '../../components/Wysiwyg';

import styles from './NotFoundPage.module.scss';
import SearchModal from '../../components/SearchModal';

const NotFoundPage = ({ title, html, searchModal }) => {
    return (
        <div className={styles['NotFoundPage']}>
            <div className={styles['NotFoundPage__Container']}>
                <div className={styles['NotFoundPage__Content']}>
                    <h1 className={styles['NotFoundPage__Title']}>{title}</h1>
                    <Wysiwyg modifier={'NotFoundPage'} html={html} />
                    <div className={styles['NotFoundPage__SearchModal']}>
                        <SearchModal
                            searchPageUrl={'/sok/'}
                            modifier={'NotFoundPage'}
                            buttonColor="white"
                            {...searchModal}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

NotFoundPage.propTypes = {
    title: PropTypes.string,
    html: PropTypes.string,
};

NotFoundPage.defaultProps = {
    title: '',
    html: '',
};

export default basePageWrap(NotFoundPage);
